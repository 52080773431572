import store from '@/store';

const themeUrls = store.getters['settings/themeUrls'];

const menu = [
  {
    id: 'calendar',
    name: 'header.events',
    link: `${themeUrls.Events}/events`,
    icon: 'calendar',
  },
  {
    id: 'results',
    name: 'header.results',
    link: themeUrls.Results,
    icon: '1st-place-medal',
  },
  {
    id: 'payment',
    name: 'header.payment',
    link: `${themeUrls.Events}/Home/StaticPayments`,
    icon: 'caret-right',
  },
  {
    id: 'contacts',
    name: 'header.contacts',
    link: `${themeUrls.Events}/Home/StaticContacts`,
    icon: 'caret-right',
  },
];

const mobileMenu = [
  { isDivider: true },
  {
    name: 'header.my_account',
    link: `${themeUrls.Events}/Profile`,
    icon: 'user',
  },
  {
    name: 'header.organizer_panel',
    link: 'https://admin.russiarunning.com/',
    icon: 'caret-right',
    roles: ['isAdmin', 'isBeneficiary'],
  },
];

const profileMenu = [
  {
    name: 'header.profile',
    icon: 'user',
    link: `${themeUrls.Events}/Account`,
  },
  {
    name: 'header.registrations',
    icon: 'badge',
    link: `${themeUrls.Events}/Account/RegistrationList`,
  },
  {
    name: 'header.orders',
    icon: 'shopping-bag',
    link: `${themeUrls.Events}/Account/OrderList`,
  },
  {
    isDivider: true,
    roles: ['isAdmin', 'isBeneficiary'],
  },
  {
    name: 'header.organizers',
    icon: 'caret-right',
    link: 'https://admin.russiarunning.com/',
    roles: ['isAdmin', 'isBeneficiary'],
  },
  {
    name: 'header.bills',
    icon: 'caret-right',
    link: `${themeUrls.Events}/Account/Bills`,
    roles: ['isAdmin'],
  },
  {
    name: 'header.cache',
    icon: 'caret-right',
    link: `${themeUrls.Events}/CacheAdmin`,
    roles: ['isAdmin'],
  },
];

export {
  menu,
  mobileMenu,
  profileMenu,
};
