<template>
  <div>
    <template v-if="isMobile">
      <Button
        class="rr-header__menu-btn"
        variant="secondary"
        shape="circle"
        icon-left="menu"
        @click="mobileMenuOpen = true"
      >
        <span class="live-dot" v-show="live"></span>
      </Button>
      <div
        class="rr-mobile-menu"
        :class="{'active': mobileMenuOpen}"
        style="display: none"
      >
        <div
          class="rr-mobile-menu__overlay"
          @click="mobileMenuOpen = false"
        ></div>
        <div class="rr-mobile-menu__container">
          <div class="rr-mobile-menu__header">
            <a class="rr-mobile-menu__logo" :href="themeUrls.Main">
              <img :src="themeImages.logo" alt="">
            </a>
            <a class="live-img" :href="themeUrls.Live" v-if="live">
              <img src="./live.png" alt="Live" width="83">
            </a>
            <div class="rr-mobile-menu__close">
              <Button
                variant="clear"
                size="lg"
                shape="square"
                icon-left="close"
                @click="mobileMenuOpen = false"
              ></Button>
            </div>
          </div>
          <div
            class="rr-mobile-menu__body"
          >
            <div
              class="rr-mobile-menu__item"
              :class="{'open': item.open}"
              v-for="(item, i) in mobileMenu"
              :key="i"
            >
              <div class="divider" v-if="item.isDivider"></div>
              <a
                class="rr-mobile-menu__link"
                :href="!item.items && !item.action && item.link"
                @click="onMobileItemClick(item)"
                v-show="!item.roles || isInRole(item.roles)"
                v-else
              >
              <span class="rr-mobile-menu__link-icon" v-if="item.icon">
                <Icon :name="item.icon" size="m"></Icon>
              </span>
                <span>{{ $t(item.name) }}</span>
                <Icon
                  v-if="item.items"
                  class="rr-mobile-menu__link-arrow icon"
                  name="down"
                  size="m"
                ></Icon>
              </a>
              <div class="rr-mobile-menu__subitems" v-if="item.items">
                <div
                  class="rr-mobile-menu__subitem"
                  v-for="(subitem, j) in filterMenu(item.items)"
                  :key="j"
                >
                  <a class="rr-mobile-menu__link" :href="subitem.link">
                    <span>{{ $t(subitem.name) }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div ref="nav" v-else>
      <div
        ref="navContainer"
        class="rr-header-nav"
        :class="{'transparent': !menuUpdated}"
      >
        <div
          class="rr-header-nav__item"
          v-for="(menuItem, i) in menu"
          :key="i"
        >
          <a
            class="rr-header-nav__link"
            :class="menuItem.id === activeMenu && 'active'"
            :href="menuItem.link"
          >
            {{ $t(menuItem.name) }}
          </a>
        </div>
        <div class="rr-header-nav__item" v-show="menuHidden.length" style="min-width: 60px;">
          <Link
            ref="more"
            class="rr-header-nav__more"
            text="Ещё"
            variant="inherit"
            icon-right="down"
          ></Link>
          <Dropdown
            trigger="more"
            listeners="hover click"
            placement="bottom-end"
          >
            <template slot="items">
              <div
                class="base-dropdown__item"
                v-for="(menuItem, i) in menuHidden"
                :key="i"
              >
                <a
                  class="base-dropdown__link"
                  :href="menuItem.link"
                >
                  <span class="base-dropdown__text">{{ $t(menuItem.name) }}</span>
                </a>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="rr-header-nav__item" style="width: 83px;">
          <a class="live-img" :href="themeUrls.Live" v-if="live">
            <img src="./live.png" alt="Live" width="83">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getTodayEventsAmount from '@/assets/js/getTodayEventsAmount';
import { mapGetters } from 'vuex';
import Dropdown from '@/components/Dropdown/Dropdown.vue';
import ru from './locales/ru.json';
import en from './locales/en.json';

let theme = null;

export default {
  name: 'HeaderMenu',
  components: {
    Dropdown,
  },
  i18n: {
    messages: {
      ru,
      en,
    },
  },
  props: {
    isMobile: Boolean,
    live: Boolean,
    activeMenu: String,
  },
  data() {
    let menu;
    let mobileMenu;

    try {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      const headerMenu = require(`./themes/${theme}/header-menu`);
      menu = headerMenu.menu || [];
      mobileMenu = headerMenu.mobileMenu || [];
    } catch {
      menu = [];
      mobileMenu = [];
    }

    return {
      mobileMenuOpen: false,
      menu: [...menu],
      mobileMenu: [...mobileMenu],
      menuHidden: [],
      menuItemsWidth: [],
      menuUpdated: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeUrls', 'themeImages']),
    ...mapGetters('user', ['user', 'isAuthorized']),
  },
  methods: {
    isInRole(rolesArr) {
      const roles = rolesArr || [];
      return !roles.length || roles.some((role) => this.user[role]);
    },
    updateNav() {
      if (window.innerWidth < 1024) {
        this.menuUpdated = true;
        return;
      }

      const { nav } = this.$refs;
      const container = this.$refs.navContainer;

      if (!container) {
        this.menuUpdated = true;
        return;
      }

      const availableSpace = nav.offsetWidth;
      const widths = this.menuItemsWidth;

      if (container.offsetWidth > availableSpace) {
        widths.push(container.offsetWidth);
        const link = this.menu.splice(this.menu.length - 1);
        this.menuHidden.unshift(link[0]);
      } else if (availableSpace > widths[widths.length - 1]) {
        const link = this.menuHidden.splice(0, 1);
        this.menu.push(link[0]);
        widths.pop();
      }

      setTimeout(() => {
        if (container.offsetWidth > availableSpace) {
          this.updateNav();
        }
        this.menuUpdated = true;
      });
    },
    checkNav() {
      this.mobileMenu.unshift(...this.menu);

      if (this.isMobile) {
        return;
      }

      setTimeout(() => {
        this.updateNav();
        window.addEventListener('resize', this.updateNav);
      }, 10);
    },
    onMobileItemClick(item) {
      if (item.items) {
        // eslint-disable-next-line no-param-reassign
        item.open = !item.open;
      } else if (item.action) {
        item.action.call(this);
      }
    },
    filterMenu(menu) {
      return menu.filter((item) => !item.hidden);
    },
  },
  watch: {
    isAuthorized: {
      handler(val) {
        if (val) {
          this.updateNav();
        }
      },
      immediate: true,
    },
    live(val) {
      if (!this.isMobile && val) {
        this.updateNav();
      }
    },
    mobileMenuOpen(val) {
      document.body.style.overflow = val ? 'hidden' : '';
    },
    $route() {
      document.body.style.overflow = '';
    },
  },
  beforeCreate() {
    theme = this.$store.getters['settings/theme'];
  },
  mounted() {
    this.checkNav();

    const todayEventsItem = this.menu[0].items[1];

    if (!todayEventsItem) return;

    getTodayEventsAmount().then((eventsAmount) => {
      todayEventsItem.hidden = eventsAmount === 0;
    });
  },
};
</script>

<style lang="scss" scoped>
.rr-header__menu-btn {

  ::v-deep .rr-button__icon {
    margin: 0 !important;
  }
}
.rr-header-nav {
  display: inline-flex;
  align-items: center;
  min-width: 100%;

  &__item {
    margin-right: 20px;
  }

  &__link {
    display: block;
    color: inherit;
    font-weight: $font-weight-semibold;
    text-decoration: none;
    position: relative;
    padding: 4px 0;
    white-space: nowrap;

    &::before {
      content: "";
      display: block;
      width: 0;
      height: 2px;
      background-image: $gradient-primary-horizontal;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }

    &.active::before,
    .no-touch &:hover::before {
      width: 100%;
      transition: $transition-default;
    }
  }

  &__more {
    opacity: 1;
    flex-wrap: nowrap;

    ::v-deep .rr-link__icon {
      margin-left: 4px;
    }
  }
}

.rr-mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-width: 100%;
  z-index: 10;
  pointer-events: none;
  display: block !important;

  &.active {
    pointer-events: auto;
  }

  &__overlay {
    opacity: 0;
    transition: $transition-default opacity;
    background-color: $color-black-85;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .rr-mobile-menu.active & {
      opacity: 1;
    }
  }

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 296px;
    max-width: 100%;
    height: 100%;
    background-color: $color-white;
    overflow-x: hidden;
    transform: translateX(-100%);
    transition: $transition-default transform;

    .rr-mobile-menu.active & {
      transform: none;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin-bottom: 8px;

    > * {
      flex: 0 0 auto;
    }
  }

  &__body {
    padding: 20px 24px 60px;
  }

  &__logo {
    display: block;
    width: var(--header-mobile-logo-width, 40px);
    margin: 4px;
  }

  &__close {
    width: 48px;

    .button {
      position: fixed;
      transform: translateY(-50%);

      &::after {
        content: none;
      }
    }
  }

  &__item {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }

    .divider {
      margin: -4px 0;
    }
  }

  &__link {
    display: inline-flex;
    vertical-align: top;
    align-items: center;
    color: inherit;
    font-weight: $font-weight-semibold;
    text-decoration: none;
  }

  &__link-icon {
    flex: 0 0 auto;
    line-height: 1;
    margin-right: 16px;
  }

  &__link-arrow {
    flex: 0 0 auto;
    line-height: 1;
    margin-left: 4px;
    margin-right: 16px;

    .rr-mobile-menu__item.open & {
      transform: rotate(180deg);
    }
  }

  &__subitems {
    display: none;
    padding-left: 48px;

    .rr-mobile-menu__item.open & {
      display: block;
    }
  }

  &__subitem {
    @include text-sm;
    margin-top: 12px;
  }
}

.live-img {
  display: block;

  img {
    max-width: none;
  }
}

.live-dot {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 7px;
  height: 7px;
  background-color: $color-danger;
  border-radius: 50%;
  border: 1px solid $color-white;
  margin: 7px;
  animation: rr-pulse-red 2s infinite;
}
</style>
