<template>
  <header
    class="rr-header"
    :class="{'rr-header_scrolled': isScrolled}"
  >
    <div class="rr-header__wrap">
      <div
        class="rr-header__container"
        :class="{'rr-header__container_md': !isWide}"
      >
        <!-- Mobile menu : BEGIN -->
        <div class="rr-header__col d-lg-none">
          <HeaderMenu
            class="rr-header__col-item"
            :live="live"
            :activeMenu="activeMenu"
            is-mobile
          ></HeaderMenu>
        </div>
        <!-- Mobile menu : END -->
        <div class="rr-header__col rr-header__col_center-mobile">
          <div class="rr-header__col-item">
            <a
              :href="themeUrls.Main"
              class="rr-header__logo"
              :class="{'rr-header__logo_themed' : theme !== 'rr'}"
            >
              <span
                v-if="theme === 'rr'"
                class="rr-header-logo"
              >
                <img :src="themeImages.logo" alt="" class="rr-header-logo__symbol">
              </span>
              <img
                v-else
                :src="themeImages.logo" alt=""
              >
            </a>
          </div>
          <!-- Desktop menu : BEGIN -->
          <HeaderMenu
            class="rr-header__col-item rr-header__nav d-none d-lg-block"
            :activeMenu="activeMenu"
            :live="live"
          ></HeaderMenu>
          <!-- Desktop menu : END -->
        </div>
        <div class="rr-header__col rr-header__col_right">
          <!-- Lang switch : BEGIN -->
          <LangSwitch
            class="rr-header__col-item d-none d-lg-block"
            :locales="themeSettings.languages"
            :locale="$lang"
            :dropdownOptions="{ triggerId: 'headerLangSwitch', placement: 'bottom-end' }"
            hideCurrent
            @change="changeLang"
          >
            <template v-slot:default="{ language }">
              <Button
                id="headerLangSwitch"
                variant="clear"
                shape="square"
              >
                <span class="lang-switch__flag">
                  <img :src="language.img" :alt="language.id">
                </span>
              </Button>
            </template>
          </LangSwitch>
          <!-- Lang switch : END -->
          <!-- User / login  : BEGIN -->
          <div class="rr-header__col-item" v-if="!isAuthorized">
            <Button
              class="d-xl-none"
              variant="secondary"
              :href="`${themeUrls.Events}/login`"
              icon-left="user"
              shape="circle"
            ></Button>
            <Button
              class="d-none d-xl-flex"
              variant="secondary"
              :text="$t('header.my_account')"
              icon-left="user"
              :href="`${themeUrls.Events}/login`"
            >
            </Button>
          </div>
          <div class="rr-header__col-item rr-header__profile" v-else>
            <button class="rr-avatar" ref="avatar" type="button">
              <Avatar
                :username="user.fullName"
                :img="user.avatarSmallUrl"
              >
              </Avatar>
            </button>
            <Dropdown
              trigger="avatar"
              listeners="hover click"
              placement="bottom-end"
            >
              <template slot="items">
                <div
                  class="base-dropdown__item"
                  v-for="(profileItem, i) in profileNav"
                  v-show="!profileItem.roles || isInRole(profileItem.roles)"
                  :key="i"
                >
                  <div class="divider" v-if="profileItem.isDivider"></div>
                  <a class="base-dropdown__link" :href="profileItem.link" v-else>
                    <Icon class="base-dropdown__icon" :name='profileItem.icon'></Icon>
                    <span class="base-dropdown__text">{{ $t(profileItem.name) }}</span>
                  </a>
                </div>
                <div class="divider" v-show="profileNav.length"></div>
                <div class="base-dropdown__item">
                  <a :href="`${themeUrls.Events}/Auth/LogOff`" class="base-dropdown__link">
                    <Icon class="base-dropdown__icon" name="logout"></Icon>
                    <span class="base-dropdown__text">{{ $t('header.sign_out') }}</span>
                  </a>
                </div>
              </template>
            </Dropdown>
          </div>
          <!-- User / login  : END -->
        </div>
      </div>
    </div>
    <a
      v-show="showLiveTile"
      :href="liveTileUrl"
      class="live-tile-widget">
      live
    </a>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import eventsApi from '@/services/events-api';
import { setCulture } from '@/utils';
import Avatar from '@/components/Avatar/Avatar.vue';
import Dropdown from '@/components/Dropdown/Dropdown.vue';
import LangSwitch from '@/components/LangSwitch/LangSwitch.vue';
import HeaderMenu from './HeaderMenu.vue';
import ru from './locales/ru.json';
import en from './locales/en.json';

let theme = null;

export default {
  name: 'Header',
  i18n: {
    messages: {
      ru,
      en,
    },
  },
  props: {
    activeMenu: String,
    isWide: Boolean,
    hideLang: Boolean,
    title: String,
  },
  components: {
    HeaderMenu,
    Avatar,
    Dropdown,
    LangSwitch,
  },
  data() {
    let profileNav;

    try {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      const headerMenu = require(`./themes/${theme}/header-menu`);
      const profileMenu = headerMenu.profileMenu || [];
      profileNav = [...profileMenu];
    } catch {
      profileNav = [];
    }

    return {
      profileNav,
      currentLang: this.$lang,
      isScrolled: false,
      live: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeUrls', 'themeImages', 'themeSettings']),
    ...mapGetters('user', ['user', 'isAuthorized']),
    showLiveTile() {
      return this.live && this.$route.path === '/';
    },
    liveTileUrl() {
      if (/localhost|rr-dev/.test(window.location.hostname)) {
        return 'https://live.rr-dev.ru';
      }

      return 'https://live.russiarunning.ru';
    },
  },
  methods: {
    isInRole(rolesArr) {
      const roles = rolesArr || [];
      return !roles.length || roles.some((role) => this.user[role]);
    },
    toggleHeader() {
      this.isScrolled = window.pageYOffset > 10;
    },
    async getLiveCount() {
      const result = await eventsApi.getLiveCount();
      if (result.data > 0) {
        this.live = true;
      }
    },
    changeLang(lang) {
      setCulture(lang, this.themeUrls.Events);
    },
  },
  beforeCreate() {
    theme = this.$store.getters['settings/theme'];
  },
  mounted() {
    document.removeEventListener('scroll', this.toggleHeader);
    document.addEventListener('scroll', this.toggleHeader);
    if (!this.themeSettings.noLive) {
      this.getLiveCount();
    }
  },
};
</script>

<style lang="scss" scoped>
// live tile start
.live-tile-widget {
  display: none;
}
@media (max-width: 768px) {
  .live-tile-widget {
    display: block;
    position: fixed;
    bottom: 30px;
    left: -17px;
    text-decoration: none !important;
    transform: rotate(270deg);
    z-index: 1;
    color: #fff !important;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #ED1C24;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    padding: 11px 19px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    display: block;
  }
}
// live tile end

.rr-header {
  --font-weight-semibold: 600;

  font-family: 'Montserrat', sans-serif;
  background-color: $color-white;
  box-shadow: $elevation-deth-8;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  @include text-md;

  .rr-button {
    font-family: inherit;
  }

  &__wrap {
    background-color: inherit;
    padding-left: 8px;
    padding-right: 8px;

    @media (min-width: breakpoint(md)) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &__container, &__logo {
    height: 56px;

    @media (min-width: breakpoint(md)) {
      height: 64px;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    &_md {
      max-width: 1224px;
    }
  }

  &__col {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
    margin: 0 -10px;
    align-items: center;

    &_right {
      justify-content: flex-end;

      @media (min-width: breakpoint(lg)) {
        flex: 0 1 auto;
      }
    }

    &_center {
      justify-content: center;
      flex-basis: auto;
    }

    &_center-mobile {

      @media (max-width: breakpoint(lg, max)) {
        justify-content: center;
        flex-basis: auto;
      }
    }
  }

  &__col-item {
    flex: 0 0 auto;
    margin-left: 10px;
    margin-right: 10px;
    min-width: 0;
  }

  &__col-divider {
    flex: 0 0 auto;
    width: 1px;
    height: 20px;
    background-color: $color-black-15;
    margin: 0 8px;

    @media (min-width: breakpoint(md)) {
      margin: 0 10px;
    }
  }

  &__logo {
    display: block;
    position: relative;
    width: 80px;

    &::before {
      content: "";
      position: absolute;
      display: block;
      background: $color-white;
      box-shadow: $elevation-deth-4;
      width: 100%;
      padding-top: 100%;
      border-radius: 50%;
      z-index: -1;
    }

    @media (min-width: breakpoint(md)) {
      width: 104px;
    }

    @media (min-width: breakpoint(lg)) {
      margin-left: -4px;
      margin-right: 8px;
    }

    @media (min-width: breakpoint(xl)) {
      margin-right: 32px;
    }

    .rr-header_external & {
      margin-right: -12px;
    }

    &_themed {
      display: flex;
      width: var(--header-logo-width, auto);

      &::before {
        content: none;
      }

      img {
        width: var(--header-logo-width, auto);
        margin: auto 0;
      }
    }
  }

  &__profile {
    position: relative;
  }

  &__nav {
    flex-grow: 1;
    flex-shrink: 1;
  }

  &__title {
    color: $color-black-85;
  }

  &__to-site {
    @include text-sm;
    margin-right: 8px;

    .icon {

      @media (max-width: breakpoint(md, max)) {
        margin: 0;
      }
    }
  }

  &__to-site-text {

    @media (max-width: breakpoint(md, max)) {
      display: none;
    }
  }
}

.rr-header-logo {
  display: block;
  width: 100%;
  padding-top: 100%;
  position: relative;
  border-radius: 50%;

  &__symbol {
    display: block;
    position: absolute;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

.rr-header-logo, .rr-header__logo::before {
  transform-origin: top center;
  transition: $transition-default;
  transition-property: box-shadow, transform;

  .rr-header_scrolled & {
    transform: scale(0.7);

    @media (min-width: breakpoint(md)) {
      transform: scale(0.61);
    }
  }
}

.rr-avatar {
  display: inline-block;
  background: none;
  border: none;
  padding: 0;
  vertical-align: middle;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  user-select: none;
}

@keyframes showHeader {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>

<style lang="scss">
.rr-header-offset {
  flex: 0 0 auto;
  height: 56px;

  @media (min-width: breakpoint(md)) {
    height: 64px;
  }
}
</style>
